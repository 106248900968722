<template>
  <div id="dashboard">
  </div>
</template>

<script>

export default {
  name: 'Home',
  data () {
    return {}
  },
  async created () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
